<template>
    <div class="modal-basic">
        <section>
            <div class="title">선호 일정</div>
            <div class="dates">
                <div class="item m-b-4" v-for="(date, i) in dateContent" :key="i" v-html="date" />
            </div>
        </section>
        <section>
            <div class="title">선호 만남 방식</div>
            <div class="item">{{ $translate(payload.meet_type.toUpperCase()) }}</div>
        </section>
        <section>
            <p class="spoqa-f-regular m-b-4">선택하신 내용으로 약속을 정하시겠어요?</p>
            <p class="spoqa-f-regular">변경이 어려우니 다시 한번 확인해주세요.</p>
        </section>

        <div class="button-wrapper m-t-20">
            <div class="confirm" v-html="$translate('CONFIRM')" @click="onClickButton(1)" />
            <div class="cancel m-r-16" v-html="$translate('CANCEL')" @click="onClickButton(0)" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalScheduleAlert',
    props: ['options'],
    data: () => ({
        dateContent: [],
    }),
    mounted() {
        for (let i = 1; i <= 5; i++) {
            const ymd = this.$moment(this.payload[`date_${i}`]).format('YY/MM/DD A h:mm')
            const day = this.$moment(this.payload[`date_${i}`]).format('dddd').toUpperCase()
            if (this.payload[`date_${i}`]) this.dateContent.push(`${ymd} ${this.$translate(day)}`)
        }
    },
    methods: {
        onClickButton(idx) {
            this.$emit('close', idx)
        },
    },
    computed: {
        payload() {
            return this.options.payload
        },
    },
}
</script>

<style scoped lang="scss">
section {
    @include spoqa-f-medium;
    margin-bottom: 16px;
    color: black;
    font-size: 14px;

    .title {
        color: #94999e;
        margin-bottom: 8px;
        font-size: 12px;
    }
}
.button-wrapper {
    display: flex;
    flex-direction: row-reverse;

    .cancel {
        width: fit-content;
    }
    .confirm {
        @extend .cancel;
        color: $blue-primary;
    }
}
</style>
